<template>
	<main class="main">
		<section class="container">
			<Crumbs :data="crumbs"></Crumbs>
			<template v-if="item && item != 404">
				<div class="main__box">
					<div class="partner">
						<div class="grid__link grid__link--no-hover">
							<div class="grid__logo-box">
								<img
									v-if="item.acceptorInfo"
									:src="item.banner"
									:alt="item.name"
									class="grid__logo"
								/>
								<!--
								<img
									v-if="item.acceptorInfo && item.acceptorInfo.logo"
									:src="item.acceptorInfo.logo"
									:alt="item.name"
									class="grid__logo"
								/>
								-->
								<p class="main__text text-center" v-else>{{ item.name }}</p>
							</div>
							<div class="grid__content-box">
								<div class="grid__content" v-if="item.acceptorInfo">
									<h3 class="grid__title" v-if="item.acceptorInfo.name">
										<!--{{ item.acceptorInfo.name }}-->
										{{ item.name }}
									</h3>
									<!--
									<a
										:href="'tel:' + item.acceptorInfo.phone"
										class="link"
										v-if="item.acceptorInfo.phone"
									>
										<span class="icon icon--phone"></span>
										{{ item.acceptorInfo.phone }}
									</a>
									-->
								</div>
								<div class="grid__button-box">
									<a :class="item.webSiteURL ? '' : 'button-disabled'" :href="item.webSiteURL ? item.webSiteURL : 'javascript:void(0);'" :target="item.webSiteURL ? '_blank' : '_self'" class="button"
										>Веб-сайт</a
									>
								</div>
							</div>
						</div>
						<div class="partner__content">
							<div
								class="stocks__banner-box"
								v-if="loyaltyDescription.length > 0"
							>
								<img
									:src="item.banner"
									:alt="loyaltyDescription[0].title"
									class="stocks__banner"
								/>
							</div>

							<ul class="partner__list" v-if="loyaltyDescription.length > 0">
								<PartnerCollapseItem
									:data="item"
									v-for="(item, index) in loyaltyDescription"
									:key="index"
								></PartnerCollapseItem>
							</ul>
							<!--
							<Map
								v-if="mapMarkers && mapMarkers.length > 0"
								:data="{
									icon: require('@/assets/images/icons/map_type-2.svg'),
									markers: item.departments,
								}"
							></Map>
							-->
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="item == 404">
				<div class="flex flex--center">
					<p class="error-page__text text-gray">
						<span class="visually-hidden">Ошибка:</span>
						<span class="error-page__code">404</span>
						<span class="error-page__text-row">Акция</span>
						<span class="error-page__text-row error-page__text-row--text-indent"
							>не найдена</span
						>
					</p>
				</div>
			</template>
		</section>

		<section class="container stocks">
			<Partners
				:data="listLoyaltyPrograms"
				v-if="listLoyaltyPrograms"
			></Partners>
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import Partners from '@/components/Partners.vue'
import PartnerCollapseItem from '@/components/PartnerCollapseItem.vue'
//import Map from '@/components/Map.vue'

export default {
	name: 'StockItem',

	components: {
		Crumbs,
		Partners,
		PartnerCollapseItem,
		//Map,
	},

	props: ['id'],

	data() {
		return {
			partner: null,
			partners: null,
		}
	},
	computed: {
		item() {
			const list = this.$store.getters.LIST_LOYALTY_PROGRAMS
			const acceptors = this.$store.getters.LIST_ACCEPTORS

			if (list) {
				let item = list.filter(item => parseInt(item.id) == parseInt(this.id))

				if (item.length > 0) {
					document.title = item[0].name

					if (acceptors) {
						const acceptor = acceptors.filter(
							acceptor => acceptor.id == item[0].acceptorId
						)

						item = [{ ...item[0], acceptorInfo: acceptor[0] }]
					}
					return item[0]
				}

				return 404
			} else {
				return null
			}
		},

		acceptor() {
			return this.getters.LIST_ACCEPTORS
		},

		mapMarkers() {
			return this.$store.getters.LIST_LOYALTY_PROGRAMS
		},

		loyaltyDescription() {
			const data = this.item
			const description = new Array()

			if (data.descriptionFull) {
				description.push({
					title: data.name,
					text: data.descriptionFull,
					collapse: false,
					contentHide: false,
				})
			}

			if (data.rules) {
				description.push({
					title: 'Правила акции',
					text: data.rules,
					collapse: true,
					contentHide: true,
				})
			}

			return description
		},

		listLoyaltyPrograms() {
			const stocksList = this.$store.getters.LIST_LOYALTY_PROGRAMS
			const acceptors = this.$store.getters.LIST_ACCEPTORS

			let data = stocksList

			if (stocksList && acceptors) {
				data = []

				stocksList.forEach(stock => {
					acceptors.forEach(acceptor => {
						if (stock.acceptorId == acceptor.id) {
							stock.acceptorInfo = acceptor

							data.push(stock)
						}
					})
				})
			}

			if (stocksList) {
				return {
					title: 'Акции',
					link: 'StocksItem',
					filterDispatch: 'SET_LIST_APPLIED_STOCKS_FILTERS',
					filterGetters: 'LIST_APPLIED_STOCKS_FILTERS',
					filterAll: true,
					removeItem: this.id,
					list: data,
				}
			} else {
				return null
			}
		},

		crumbs() {
			const data = this.item
			let text = data ? data.name : ''

			return [
				{
					link: 'Stocks',
					text: 'Акции',
				},
				{
					link: null,
					text,
				},
			]
		},
	},

	mounted() {
		this.$store.dispatch('GET_LIST_LOYALTY_PROGRAMS')

		this.$store.dispatch('GET_LIST_ACCEPTORS')
	},
}
</script>
